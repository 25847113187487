$btn-focus-box-shadow: none !important;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import '~bootstrap/scss/bootstrap';

* {
  transition-duration: 300ms;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #072366 !important;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #000807;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

.color-primary {
  color: #3626A7;
}

.bg-offwhite {
  background-color: #FBFBFF;
}

a {
  color: #3626A7;
}

a:hover {
  color: #657ED4;
}

.side-list {
  list-style: none;
}

.side-list>li {
  list-style-image: url("./static/next.svg");
}

.fa {
  margin-right: 5px;
  padding: 5px 5px 5px 0px;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

.fa:hover {
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

.btn-gradient {
  display: inline-flex;
  align-items: center;
  color: white;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(130deg, #048BA8, #3626A7);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.btn-gradient:hover {
  opacity: 0.8;
  color: white;
}

.badge-main {
  background-color: #657ED4;
  color: #FBFBFF;
}

.badge-main:hover {
  background-color: #3626A7;
}

.btn-outline-main {
  color: #657ED4;
  background-color: #ffffff;
  border-color: #657ED4;
}

.btn-outline-main:hover,
.btn-outline-main:active,
.btn-outline-main:focus,
.btn-outline-main.active {
  background: #3626A7;
  color: #ffffff;
  border-color: #3626A7;
}

.section-title {
  position: relative;
  overflow: hidden;
}

.section-title::after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #3626A7;
}

.section-sub {
  position: relative;
  overflow: hidden;
}

.section-sub::before {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 15px;
  border-top: 1px solid #3626A7;
}

.side-bar {
  position: fixed;
  z-index: 99;
}

.nav-bar {
  position: fixed;
  right: 0rem;
  z-index: 10;
}

@media (max-width: 767.98px) {
  .side-bar {
    position: relative;
    z-index: 99;
  }

  .nav-bar {
    position: relative;
  }
}

.skills-img-container {
  text-align: justify;
}

.skills-img-container>img {
  width: 50px;
  margin: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #495057 !important;
}

.form-control {
  border-radius: 0;
  border: none;
  border-bottom: 3px solid rgba(101, 126, 212, 0.1);
  background-color: transparent;
}

.form-control::placeholder {
  color: transparent;
}

.form-group {
  position: relative;
}

.form-control,
.floating-label {
  line-height: 1;
  font: inherit;
  padding: 1em;
  padding-left: 0.5em;
  height: 3em;
}

.floating-label {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  z-index: 99;
  transition: transform .25s, opacity .25s ease-in-out;
  transform-origin: 0 0;
  opacity: 0.9;
  color: rgba(101, 126, 212, 0.9);
}

.form-control:focus,
.form-control:not(:placeholder-shown) {
  border-color: rgba(101, 126, 212, 0.3)
}

.form-control:focus+.floating-label,
.form-control:not(:placeholder-shown)+.floating-label {
  transform: translate(1%, -40%) scale(.8);
  opacity: .7;
}

#messageContact {
  resize: none;
}

.hidden {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(.17, .67, .69, .91);
}

.hidden.hide {
  opacity: 0;
}
